export interface Page {
  permission?: string;
  name: string;
  path: string;
  icon: string;
}

// export const homePage: Page = {
//     name: 'Take me Back',
//     path: process.env.NEXT_PUBLIC_OLD_DASHBOARD_URL + 'index',
//     icon: 'fa-arrow-left'
// }
//
// export const insightsPage: Page = {
//     name: 'Insights',
//     path: 'https://www.cartracked.com/integrations',
//     //path: '/v2/insights',
//     icon: 'fa-chart-bar'
// }

export const dashboardPage: Page = {
  name: "Dashboard",
  path: `/v2/dashboard`,
  icon: "fa-tachometer-alt",
};
export const newSalePage: Page = {
  name: "New Sale",
  path: `${process.env.NEXT_PUBLIC_BASE_URL + "/view-sale"}`,
  icon: "fa-money-check-alt",
  permission: "CREATE_NEW_SALE",
};

export const salesLogPage: Page = {
  name: "Sales Log",
  path: `${process.env.NEXT_PUBLIC_BASE_URL + "/sales-log"}`,
  icon: "fa-tasks",
  permission: "VIEW_SALES_LOG",
};

export const salesPersonSalesLog: Page = {
  name: "Sales Log",
  path: `${process.env.NEXT_PUBLIC_BASE_URL + "/sp-saleslog"}`,
  icon: "fa-tasks",
  permission: "VIEW_SALES_LOG_PARTIAL",
};

export const scheduledDeliveriesPage: Page = {
  name: "Scheduled Deliveries",
  path: `${process.env.NEXT_PUBLIC_BASE_URL + "/delivery-calendar"}`,
  icon: "fa-car",
  permission: "VIEW_DELIVERY_SCHEDULE",
};

export const usersPage: Page = {
  name: "Users",
  path: `${process.env.NEXT_PUBLIC_BASE_URL + "/users"}`,
  icon: "fa-users",
  permission: "VIEW_USERS",
};
export const dueBills: Page = {
  name: "Due Bills",
  path: `${process.env.NEXT_PUBLIC_BASE_URL + "/duebill"}`,
  icon: "fa-clipboard-list",
  permission: "ACCESS_DUEBILLS",
};
export const configure: Page = {
  name: "Configure",
  path: `${process.env.NEXT_PUBLIC_BASE_URL + "/configure"}`,
  icon: "fa-cogs",
  permission: "ACCESS_CONFIGURATION",
};
export const salesLogNew: Page = {
  name: "Sales Log (New)",
  path: `v2/sales-log`,
  icon: "fa-tasks",
  permission: "VIEW_SALES_LOG",
};
export const salesLogSalesPersonNew: Page = {
  name: "Sales Log (New)",
  path: `v2/sales-log`,
  icon: "fa-tasks",
  permission: "VIEW_SALES_LOG_PARTIAL",
};

export const deliveryCalendarNew: Page = {
  name: "Scheduled Deliveries (New)",
  path: `v2/delivery-calendar`,
  icon: "fa-car",
  permission: "VIEW_DELIVERY_SCHEDULE",
};

export type DealershipPages = {
  [key: string]: Page;
};

export const defaultDealershipPages: DealershipPages = {
  dashboard: dashboardPage,
  "new-sale": newSalePage,
  "sales-log": salesLogPage,
  "sp-saleslog": salesPersonSalesLog,
  "delivery-calendar": scheduledDeliveriesPage,
  users: usersPage,
  duebill: dueBills,
  configure: configure,
};

export const navPages: Page[] = [
  dashboardPage,
  newSalePage,
  salesLogPage,
  salesPersonSalesLog,
  scheduledDeliveriesPage,
  usersPage,
  dueBills,
  configure,
  // salesLogNew,
  // deliveryCalendarNew,
];
export const navPagesMobile: Page[] = [
  dashboardPage,
  salesLogNew,
  deliveryCalendarNew,
];

export type MobileTheme = {
  LIGHT: string;
  MED: string;
  MED2: string;
  SELECTED: string;
  DARK: string;
  EXTRALIGHT: string;
  TRACKEDGREEN: string;
  SLIDE: string;
  BACKGROUND: string;
  SHADOW: string;
  FONT1: string;
  FONT2: string;
  FONT3: string;
  FONTWHITE: string;
  NEW: string;
  USED: string;
};

export type Delivery = {
  id: number;
  scheduledDeliveryDate: string;
  delivered: boolean;
  stockNumber: string;
  status: string;
  carDescription: string;
  customer: string;
  salesPerson: string;
  saleId: string;
};
export type DeliveryWithExtras = {
  delivery: CalDelivery;
  vin: string;
  fsmManagerName: string;
};

export type CalDelivery = {
  carDescription: string;
  createdTimestamp: string;
  customer: string;
  dealershipId: string;
  delivered: boolean;
  id: string;
  saleId: string;
  salesPerson: string;
  scheduledDeliveryDate: string;
  status: string;
  stockNumber: string;
  vin?: string;
  fsmManagerName?: string;
};

export type SalesBreakdownData = {
  [key: string]: {
    units: number;
    frontGross: number;
    backGross: number;
  };
};

export type SalesOverviewOptions = {
  label: string;
  value: string;
};

export type BarChartOptions = {
  amount: number;
  key: string;
  percentage: number;
  color: any;
}[];

export const dealStatusColors = {
  PENDING: "#f4f4f4",
  FACTORY_ORDERED: "#fffc5c",
  BOOKED: "#42f58d",
  FINALIZED: "#00c6c6",
  CANCELLED: "#ff0000",
};

export const saleTypeColors = {
  RETAIL: "#00c6c6",
  WHOLESALE: "#fc7c27",
  WHOLESALE_DIRECT: "#ff0000",
  FLEET: "#4877f8",
  CREDIT_SOLUTIONS: "#42f58d",
  DLA: "#9238f3",
};

export const dealTypeColors = {
  CASH: "#fdae3f",
  LEASE: "#42f58d",
  FINANCE: "#00c6c6",
};

export type Sale = {
  id: string;
  saleDate: Date;
  stockNumber: string;
  vehicle: string;
  dealNumber: string;
  fiGross: number;
  retailGross: number;
  saleStatus: string;
  customer: string;
  deliveryStatus: string;
  scheduledDeliveryDate: string;

  make: string;
  model: string;
  trim: string;
  salesPersonName: string;
  salesManagerName: string;
  fsmManagerName: string;

  age: string;
  bank: string;
  gtg: boolean;
  isATradeIn: boolean;
  cashConverted: boolean;
  saleType: string;
  dealershipId: string;
};

export type DealershipConfiguration = {
  businessDays: number;
  unitsSoldTarget: number;
  salesGrossTarget: number;
  backendGrossTarget: number;
  backendGPUTarget: number;
  frontendGPUTarget: number;
};

export type DealershipInvitation = {
  _id: string;
  dealershipId: string;
  token: string;
  expiry: Date;
  groupId: string;
};
